jQuery(document).ready(function($) {
  if ($('#load').length) {
    /**
     * image order:
     * 0 3 4
     * 5 2 1
     */
    let loadingImages = gsap.utils.toArray("#load .images img")
    let reorderedLoadingImages = [
      loadingImages[0],
      loadingImages[5],
      loadingImages[4],
      loadingImages[1],
      loadingImages[2],
      loadingImages[3],
    ]

    let mainImageAnim = gsap.timeline({
      paused: true,
      onComplete() {
        $(document.body).removeClass('stop-scroll')
        $('#load').addClass('remove')
      },
    })
      .to('#load .text_container', {
        yPercent: -100,
        ease: 'expo.out',
        duration: .5,
      })
      .fromTo(reorderedLoadingImages, {
        clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
      }, {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        ease: 'power2.out',
        duration: 1.3,
        stagger: .15,
      })
      .to('#load .images img', {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        ease: 'power2.out',
        duration: 1,
      })
      .fromTo('#load', {
        height: '100vh',
      }, {
        height: '0vh',
        ease: 'power2.out',
        duration: 1,
      }, '>-.4')
      .fromTo('#animation .wrapper', {
        clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
      }, {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        ease: 'power3.out',
        duration: 1,
      }, '>-.4')

    document.addEventListener("loadingend", ()=>{
      mainImageAnim.play()
    });    
  }
})
